/* You can add global styles to this file, and also import other style files */

@import "@angular/material/prebuilt-themes/deeppurple-amber.css";

@import "../node_modules/leaflet/dist/leaflet.css";

html,

 body {
  height: 100%;
  background-color: whitesmoke;
}

/*body {
  height: 100%;
  background-color: #f5f5f5;
  background-image: url('./assets/misc/icon/bg_pattern.png');
  background-repeat: repeat;

  box-shadow: inset 0 0 10px #000000;
}*/

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}




/*.mat-expansion-panel-header-title, .mat-expansion-panel-header-description {
  display: flex;
  flex-grow: 1;
  margin-right: 16px;
  align-items: center;
  flex-basis: auto;
}*/

.mat-expansion-panel-header-title, .mat-expansion-panel-header-description {
  display: flex;
  flex-grow: 1;
  margin-right: 16px;
  align-items: center;
  flex-basis: auto !important;
}

/*
 * Popup Shared
 */

.pop .leaflet-popup-content-wrapper {
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  border-radius: 10px;
  text-align: center;

  background: #ffffff;
  border-style: solid;
  border-width: 4px;
}

.popup-edit-btn {
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 14px;
  font-weight: bold;
  padding: 10px 17px;
  text-decoration: none;
  border-radius: 5px;
}

/*
 * Node popup
 */

.node-popup .leaflet-popup-content-wrapper {
  border-color: #3086c8;
}

.node-popup-edit-btn {
  background: linear-gradient(to bottom, #3086c8 5%, #3086c8 100%);
  background-color: #3086c8;
  border: 1px solid #314179;
  box-shadow: inset 0px 1px 0px 0px #7a8eb9;
}

.node-popup-edit-btn:hover {
  background: linear-gradient(to bottom, #3f51b1 5%, #3f51b1 100%);
  background-color: #3f51b1;
}

.node-popup-edit-btn:active {
  position: relative;
  top: 1px;
}

/*
 * Resource
 */

.resource-popup .leaflet-popup-content-wrapper {
  border-color: #da172d;
}

.resource-popup-edit-btn {
  background: linear-gradient(to bottom, #da172d 5%, #da172d 100%);
  background-color: #da172d;
  border: 1px solid #691616;
  box-shadow: inset 0px 1px 0px 0px #b97a8f;
}

.resource-popup-edit-btn:hover {
  background: linear-gradient(to bottom, #c40000 5%, #c40000 100%);
  background-color: #c40000;
}

.resource-popup-edit-btn:active {
  position: relative;
  top: 1px;
}

/*
 *
 */

.list-row {
  background: #f4f4f4;
  display: inline-block;
  border: 1px solid black;
}

.list-left {
  width: auto;
  padding: 5px;
  float: left;
  border: 1px solid black;
}

.list-right {
  padding: 5px;
  float: right;
  border: 1px solid black;
}

.list-title {
  font-size: 14px;
  padding: 5px;
}

.list-filters {
  padding: 5px;
}

/*
 *  Info box
 */

div.boxinfo {
  text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  color: rgba(0, 0, 0, 0.87);
  font-feature-settings: "kern", "liga";
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1;
  -webkit-print-color-adjust: exact;
          print-color-adjust: exact;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
  position: relative;
  margin: 1.5625em 0;
  padding: 0 0.6rem;
  border-left: 0.2rem solid #448aff;
  border-radius: 0.1rem;
  overflow: auto;
  border-left-color: #00b8d4;
  width: 50%;
}

.boxinfo p {
  padding-top: 0.6rem;
}

p.boxinfotitle::after {
  content: "";
  background: url("/assets/misc/icon/icon-info.png");
  background-size: cover;
  height: 22px;
  width: 22px;
  position: absolute;
  left: 0.3rem;
  z-index: 0;
}

p.boxinfotitle {
  text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  color: rgba(0, 0, 0, 0.87);
  font-feature-settings: "kern", "liga";
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.6;
  -webkit-print-color-adjust: exact;
          print-color-adjust: exact;
  box-sizing: inherit;
  margin: 0 -0.6rem;
  padding: 0.4rem 0.6rem 0.4rem 2rem;
  border-bottom: 0.05rem solid rgba(68, 138, 255, 0.1);
  font-weight: 700;
  border-bottom-color: rgba(0, 184, 212, 0.1);
  background-color: rgba(0, 184, 212, 0.1);
}

/*
 *  Teaser box
 */

div.boxteaser {
  text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  color: rgba(0, 0, 0, 0.87);
  font-feature-settings: "kern", "liga";
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.6;
  -webkit-print-color-adjust: exact;
          print-color-adjust: exact;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
  position: relative;
  margin: 1.5625em 0;
  padding: 0 0.6rem;
  border-left: 0.2rem solid #c0ca48;
  border-radius: 0.1rem;
  overflow: auto;
  border-left-color: #ebefbe;
  width: 50%;
}

div.wide {
  width: 95%;
  margin: 10px auto;
}

.mat-expansion-panel.wide {
  width: 95%;
  margin: 0 auto;
}

.mat-expansion-panel.xwide {
  width: 100%;
  margin: 0 auto;
}

.mat-expansion-panel-header.colored {
  background-color: #ebefbe !important;
}

div.wideleft {
  width: 95%;
}

p.boxteasertitle::after {
  content: "";
  background: url("/assets/misc/icon/icon-docs.png");
  background-size: cover;
  height: 20px;
  width: 25px;
  position: absolute;
  left: 0.1rem;
  z-index: 0;
}

p.boxteasertitle {
  text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  color: rgba(0, 0, 0, 0.87);
  font-feature-settings: "kern", "liga";
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.6;
  -webkit-print-color-adjust: exact;
          print-color-adjust: exact;
  box-sizing: inherit;
  margin: 0 -0.6rem;
  padding: 0.4rem 0.6rem 0.4rem 2rem;
  border-bottom: 0.05rem solid rgba(235, 239, 190, 0.1);
  font-weight: 700;
  border-bottom-color: rgba(235, 239, 190, 0.1);
  background-color: rgba(235, 239, 190, 0.1);
}

div.inline {
  width: 100%;
  display: inline-block;
  padding-bottom: 4px;
  flex-wrap: nowrap;
}


div.violation {
  border: 2px red solid;
}

div.violationtext {
  color: red;
}

/*
 *  Doc info buttons
 */

.mat-raised-button.infobutton {
  background-color: #ebefbe !important;
  margin-left: 10px;
  padding-left: 2rem;
  top:0;
  bottom:0;
  margin-bottom: 10px;
}


.right {
  float: right;
}


.mat-raised-button.infobutton::after {
  content: "";
  background: url("/assets/misc/icon/icon-docs.png");
  background-size: cover;
  margin-top: 0.5rem;
  height: 20px;
  width: 25px;
  position: absolute;
  left: 0.2rem;
  z-index: 0;
}

/*
* Node detail and Resource detail button
*/

.mat-raised-button.nodedetailbutton {
  background-color: #fefff2;
  padding-left: 1.5rem;
}

.mat-raised-button.nodedetailbutton::after {
  content: "";
  background: url("/assets/maps/marker-icon-blue.png");
  background-size: cover;
  margin-top: 0.4rem;
  height: 22px;
  width: 13px;
  position: absolute;
  left: 0.2rem;
  z-index: 0;
}

.mat-raised-button.resourcedetailbutton {
  background-color: #fefff2;
  padding-left: 1.5rem;
}

.mat-raised-button.resourcedetailbutton::after {
  content: "";
  background: url("/assets/maps/marker-icon-red.png");
  background-size: cover;
  margin-top: 0.4rem;
  height: 22px;
  width: 13px;
  position: absolute;
  left: 0.2rem;
  z-index: 0;
}

/*
* ICONS
*/

.iconopacity {
  opacity: 0.7;
}

.zoomicon {
  transition: transform 0.2s ease;
}

.zoomicon:hover {
  transform: scale(1.1);
}

.dezoomicon {
  transition: transform 0.2s ease;
}

.dezoomicon:hover {
  transform: scale(0.9);
}

/*
* END ICON
*/

div.buttongroup {
  justify-content: space-between;
}

div.buttongroup > button + button {
  margin-left: 10px;
}

div.buttongroup > button + button + a {
  margin-left: 10px;
  margin-top: 10px;
  
}

div.buttongroup > button + a {
  margin-left: 10px;
  margin-top: 10px;
}

.dontbreakout {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.mat-expansion-panel-header {
  min-height: 40px;
  height: auto !important;
}

@media only screen and (max-width: 600px) {
  .mat-raised-button {
    font-size: 0.8em !important;
  }

  .disappearable {
    display: none;
  }

  div.buttongroup {
    display: flex;
    justify-content: space-between;
    align-items: center; /* centers them vertically */
  }

  div.buttongroup > button + button + a {
    margin-left: 0px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  div.buttongroup > button + a {
    margin-left: 0px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .mat-raised-button.infobutton {
    margin-left: 0px;
  }

  div.wideleft {
    /* Go to center */
    width: 95%;
    margin: 10px auto;
  }

  div.wide {
    width: 90%;
  }

  .mat-expansion-panel-header {
    height: auto !important;
  }

  .mat-toolbar-row {
    height: auto !important;
  }

}