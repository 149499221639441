.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 50%;
  text-align: center;
  background: #c0ca56;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.card-capation-image {
  height: 90px;
  padding-top: 5px;
}

.card-capation {
  padding-top: 20px;
  padding-bottom: 20px;
  margin: 0 auto;
  width: 80%;
  text-align: center;
  font-feature-settings: "kern", "liga";
  font-family: "Orbitron", sans-serif;
  line-height: 1.6;
  -webkit-print-color-adjust: exact;
  color: rgba(97, 102, 45, 0.87);
}

.card-info {
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.container {
  padding-bottom: 20px;
  background: #f2f2f2;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 2px #fff inset, -1px -1px 2px #fff inset;
  border-radius: 3px/6px;
}

.demo-loading {
  background: #61662d;
  transition: opacity 1000ms ease;
  height: 100%;
  width: 100%;
}

.demo-loading.loaded {
  opacity: 0;
}

/*
* Spinner
*/

.loader {
  margin: 0 auto;
  width: 9em;
  height: 9em;
  font-size: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader .face {
  position: absolute;
  border-radius: 50%;
  border-style: solid;
  animation: animate 3s linear infinite;
}

.loader .face:nth-child(1) {
  width: 100%;
  height: 100%;
  color: #c0ca56;
  border-color: currentColor transparent transparent currentColor;
  border-width: 0.2em 0.2em 0em 0em;
  --deg: -45deg;
  animation-direction: normal;
}

.loader .face:nth-child(2) {
  width: 70%;
  height: 70%;
  color: #61662d;
  border-color: currentColor currentColor transparent transparent;
  border-width: 0.2em 0em 0em 0.2em;
  --deg: -135deg;
  animation-direction: reverse;
}

.loader .face .circle {
  position: absolute;
  width: 50%;
  height: 0.1em;
  top: 50%;
  left: 50%;
  background-color: transparent;
  transform: rotate(var(--deg));
  transform-origin: left;
}

.loader .face .circle::before {
  position: absolute;
  top: -0.5em;
  right: -0.5em;
  content: "";
  width: 0.7em;
  height: 0.7em;
  background-color: currentColor;
  border-radius: 50%;
  box-shadow: 0 0 2em, 0 0 4em, 0 0 6em, 0 0 8em, 0 0 10em,
    0 0 0 0.5em rgba(255, 255, 0, 0.1);
}

@keyframes animate {
  to {
    transform: rotate(1turn);
  }
}

/*
* Pulsating
*/

.pulsate {
  -webkit-animation: pulsate 3s ease-out;
  animation: pulsate 3s ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  color: #61662d;
}
@keyframes pulsate {
  0% {
    color: #c0ca56;
  }
  50% {
    color: #61662d;
  }
  100% {
    color: #c0ca56;
  }
}

/*
* Small devices
*/

@media only screen and (max-width: 800px) {
  .card {
    width: 95%;
  }
}

@media only screen and (max-height: 425px) {
  .card-capation-image {
    display: none;
  }
}
